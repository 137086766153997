<template>
  <b-container class="width: 60%; margin:auto;">
          <h1 class="mt-3">RTDBox Login</h1>
          
          <b-form @submit.prevent="onSubmit">
            <b-row class="row align-items-center" style="margin: 10px 5px">
              <b-col></b-col>
              <b-col><h3>Sign In</h3></b-col>
              <b-col></b-col>
            </b-row>

            <b-row class="row align-items-center justify-content-center" style="margin: 10px 5px">
              <b-input-group>
                <b-input-group-prepend>
                  <b-input-group-text class="bg-primary text-white">Email Address:</b-input-group-text>
                </b-input-group-prepend>
                
                <b-form-input class="border" id="email" v-model="$v.email.$model" placeholder="Enter Email Address"></b-form-input>

                <b-input-group-append>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </b-input-group-append>

              </b-input-group>
            </b-row>
            <ValidationError v-bind:validation="$v.email" />
        </b-form>

        <p v-if="msg!=null">{{msg}}</p>
  </b-container>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import Axios from "axios";
import { mapMutations } from "vuex";
import ValidationError from "./components/ValidationError";

export default {
  name: 'login',

  components: { ValidationError },

  data: function() {
    return {
      email: null,
      validEmail : null,
      msg: null
    }
  },

  validations: {
    email: { required, email },
    },

  computed: {
    URLToken() {
      return (this.$route.query.token !== undefined && this.$route.query.token !== null)
      },
    },

  methods: {
    ...mapMutations({
      setEmail: "setEmail",
      }),

    storeToken: function() {
      if(this.URLToken) {
        this.setToken(this.$route.query.token);
        }
    },

    async onSubmit() {
  
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.setEmail(this.email);
        
        Axios.get("/login", {
          params: {
            email: this.email,
          },
          headers: {"Content-Type": "application/json"}
        }).then(function(response) {
          this.validEmail = response.data;
          if(this.validEmail) {
            this.msg = "An email has been sent with your access link.";
          }
          else {
            this.msg = "This email is not registered, please contact your administrator.";
          }
        }.bind(this))
        .catch(error => {
          console.log(error)
          this.errorMsg = "ERROR";
        });
      }
    },
    onReset() {
      this.email = null;
      this.$v.$reset();
    }
  }
}
</script>